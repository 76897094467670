import React, { useMemo, useContext, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";

import { OrganisationStrings, ProjectStrings } from "strings";
import {
  useAdminOrganisationTabs,
  adminOrgsTabsConfig,
} from "./userAdminOrganisationTabs";
import {
  ORGANISATION_DESC_SORT_OPTIONS,
  ORGANISATION_SORT_OPTION_KEYS,
  PROJECT_SORT_OPTION_KEYS,
  PROJECT_SORT_OPTION_FIELD,
  PROJECT_DESC_SORT_OPTIONS,
} from "./config";
import MainContext from "context/MainContext";
import { ROUTES } from "route";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

export const useAdminOrganisationConfig = () => {
  const location = useLocation();

  const {
    sortFieldValue,
    sortDescValue,
    onSort,
    onSearch,
    onAction,
    initialSort,
  } = useContext(MainContext);

  const tabs = useAdminOrganisationTabs();

  const organisationSortOptions = useMemo(
    () =>
      ORGANISATION_SORT_OPTION_KEYS.map((opt) => ({
        label: OrganisationStrings[opt],
        desc: opt in sortDescValue ? sortDescValue[opt] : ORGANISATION_DESC_SORT_OPTIONS[opt],
        selected: opt === (sortFieldValue || ORGANISATION_SORT_OPTION_KEYS[0]),
        onClick: () => onSort(opt, ORGANISATION_DESC_SORT_OPTIONS[opt]),
      })),
    [onSort, sortDescValue, sortFieldValue]
  );

  const projectSortOptions = useMemo(
    () =>
      Object.keys(PROJECT_SORT_OPTION_FIELD).map((opt) => ({
        label: ProjectStrings[opt],
        desc: opt in sortDescValue ? sortDescValue[opt] : PROJECT_DESC_SORT_OPTIONS[opt],
        selected:
        opt === (sortFieldValue || PROJECT_SORT_OPTION_KEYS[0]),
        onClick: () => onSort(opt, PROJECT_DESC_SORT_OPTIONS[opt]),
      })),
    [onSort, sortDescValue, sortFieldValue]
  );

  useEffect(() => {
    if (matchPath(location.pathname, { path: ROUTES.ADMIN_ORGANISATIONS, exact: true })) {
      const key = ORGANISATION_SORT_OPTION_KEYS[0];
      initialSort(key, ORGANISATION_DESC_SORT_OPTIONS[key]);
    }
    if (matchPath(location.pathname, { path: ROUTES.ADMIN_PROJECTS, exact: true })) {
      const key = PROJECT_SORT_OPTION_KEYS[0];
      initialSort(key, PROJECT_DESC_SORT_OPTIONS[key]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return [
    {
      path: ROUTES.ADMIN_ORGANISATIONS,
      appBar: {
        title: OrganisationStrings.ADMIN_TITLE_ORGANISATIONS,
        tabDefault: Object.keys(adminOrgsTabsConfig)[0],
        tabs,
        sort: organisationSortOptions,
        onSearch: (value) =>
          value && value.length > 0 ? onSearch(value) : onSearch(null),
      },
      actionButton: {
        actions: [
          {
            icon: <AddToQueueOutlinedIcon />,
            name: OrganisationStrings.ADD_DIALOG_CREATE,
            onClick: () =>
              onAction(GLOBAL_ACION_TYPES.ORGANISATION_CREATE, true),
          },
        ],
      },
    },
    {
      path: ROUTES.ADMIN_PROJECTS,
      appBar: {
        title: OrganisationStrings.ADMIN_TITLE_PROJECTS,
        tabDefault: Object.keys(adminOrgsTabsConfig)[1],
        tabs,
        sort: projectSortOptions,
        onSearch: (value) =>
          value && value.length > 0 ? onSearch(value) : onSearch(null),
      },
    },
  ];
};
