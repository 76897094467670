import React, { useState } from "react";

import StatCard from "./statCard/StatCard";
import {
  useOrganisationSmartTagsStatistics,
  useOrganisationDevicesOnlineState,
} from "services/OrganisationService";
import { DEVICE_PLATFORMS } from "services/DeviceService";
import { OrganisationStrings } from "strings";
import { SmartTagIcon } from "assets/icons";

const OrganisationSmartTagsStatCard = ({ organisationId, canRead, canEdit }) => {
  const [isDoAction, setIsDoAction] = useState(false);

  const stats = useOrganisationSmartTagsStatistics(organisationId);
  const onlineStates = useOrganisationDevicesOnlineState(
    isDoAction && organisationId,
    90,
    [DEVICE_PLATFORMS.QRID]
  );

  const onActionHandle = () => {
    setIsDoAction(!isDoAction);
  };

  return (
    <StatCard
      icon={<SmartTagIcon />}
      title={OrganisationStrings.OVERVIEW_SMART_TAGS_STAT_TITLE}
      loading={typeof stats === "undefined"}
      live={stats?.live || 0}
      online={stats?.online || 0}
      decommissioned={stats?.decommissioned || 0}
      charts={onlineStates}
      canRead={canRead}
      canEdit={canEdit}
      onAction={onActionHandle}
    />
  );
};

export default OrganisationSmartTagsStatCard;
