import React, { createContext, useMemo, useState, useCallback, useEffect } from "react";
import { debounce } from "services/UiService";
import { initValues } from "./initValues";
import { useLocation } from "react-router-dom";

const MainContext = createContext(initValues);

export const MainContextProvider = ({ children }) => {
    const location = useLocation();

  const [sortFieldValue, setSortFieldValue] = useState(null);
  const [sortDescValue, setSortDescValue] = useState({});
  const [searchValue, setSearchValue] = useState(null);
  const [actionType, setActionType] = useState(initValues.actionType);

  const onSortHandle = useCallback(
    (opt, defaultDesc) => {      
      if (sortFieldValue === opt) {
        // toggle the sortDescValue for the existing option
        setSortDescValue((prevState) => ({ ...prevState, [opt]: !prevState[opt] }));

      } else if (!(opt in sortDescValue)) {
        // set the sortDescValue for the new option
        setSortDescValue((prevState) => ({ ...prevState, [opt]: defaultDesc }));
      } else {
        // reset the sortDescValue for the other options
        // setSortDescValue((prevState) => ({ ...{}, [opt]: defaultDesc }));
      }
      setSortFieldValue(opt);
    },
    [sortDescValue, sortFieldValue]
  );

  const initialSortHandle = useCallback(( opt, defaultDesc) => {
      setSortFieldValue(opt);
      setSortDescValue((prevState) => ({...prevState, [opt]: defaultDesc}));
    },
    []
  );

    const onSearchHandle = debounce((value) => {
        if (searchValue === value) return;
        setSearchValue(value);
    }, 300);

    useEffect(() => {
        setSearchValue(null);
    }, [location.pathname]);

    const onActionHandle = useCallback(
        (key, action) => {
            setActionType({ ...actionType, [key]: action });
        },
        [actionType]
    );

    const contextValues = useMemo(
        () => ({
            sortFieldValue,
            sortDescValue,
            searchValue,
            actionType,
            onSort: onSortHandle,
            initialSort: initialSortHandle,
            onSearch: onSearchHandle,
            onAction: onActionHandle,
        }),
        [
            sortFieldValue,
            sortDescValue,
            searchValue,
            actionType,
            onSortHandle,
            initialSortHandle,
            onSearchHandle,
            onActionHandle,
        ]
    );

    return <MainContext.Provider value={contextValues}>{children}</MainContext.Provider>;
};

export default MainContext;
