import React, { useState } from "react";
import DevicesOutlinedIcon from "@material-ui/icons/DevicesOutlined";

import {
  useLiveDevicesCount,
  useCompliantDevicesCount,
  useOnlineDevicesCount,
  useDecommissionedDevicesCount,
  DEVICE_PLATFORMS,
} from "services/DeviceService";
import { useProjectDevicesOnlineState } from "services/ProjectService";
import StatCard from "./statCard/StatCard";
import { ProjectStrings } from "strings";

const ProjectDeviceStatCard = ({ projectId, canRead, canEdit }) => {
  const [isDoAction, setIsDoAction] = useState(false);

  const live = useLiveDevicesCount(canRead && projectId);
  const online = useOnlineDevicesCount(canRead && projectId);
  const compliant = useCompliantDevicesCount(canRead && projectId);
  const decommissioned = useDecommissionedDevicesCount(canRead && projectId);
  const platforms = Object.values(DEVICE_PLATFORMS).filter(
    (platform) => platform !== DEVICE_PLATFORMS.QRID
  );
  const charts = useProjectDevicesOnlineState(
    isDoAction && projectId,
    90,
    platforms
  );

  const onActionHandle = () => {
    setIsDoAction(!isDoAction);
  };

  return (
    <StatCard
      icon={<DevicesOutlinedIcon />}
      title={ProjectStrings.OVERVIEW_DEVICES_TITLE}
      live={live}
      online={online}
      compliant={compliant}
      decommissioned={decommissioned}
      charts={charts}
      canRead={canRead}
      canEdit={canEdit}
      onAction={onActionHandle}
    />
  );
};

export default ProjectDeviceStatCard;
