import React, { useMemo, useContext, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";
import QueuePlayNextOutlinedIcon from "@material-ui/icons/QueuePlayNextOutlined";

import { DefaultStrings, ProjectStrings } from "strings";
import { useProjectsTabs } from "./useProjectsTabs";
import { useCurrentOrganisation } from "services/UserService";
import { PROJECT_DESC_SORT_OPTIONS, PROJECT_SORT_OPTION_KEYS } from "./config";
import MainContext from "context/MainContext";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";
import { ROUTES } from "route";

export const useProjectsConfig = ({ userId }) => {
  const location = useLocation();
  const { sortFieldValue, sortDescValue, onSort, onSearch, onAction, initialSort } =
    useContext(MainContext);
  const selectedOrganisationId = useCurrentOrganisation();
  const tabs = useProjectsTabs(selectedOrganisationId);

  const { canWriteProjects } = useUserOrganisationPermissions({
    userId,
    organisationId: selectedOrganisationId,
  });

    const projectSortOptions = useMemo(
    () =>
      PROJECT_SORT_OPTION_KEYS.map((opt) => ({
        label: ProjectStrings[opt],
        desc: opt in sortDescValue ? sortDescValue[opt] : PROJECT_DESC_SORT_OPTIONS[opt],
        selected:
          opt === (sortFieldValue || PROJECT_SORT_OPTION_KEYS[0]),
        onClick: () => onSort(opt, PROJECT_DESC_SORT_OPTIONS[opt]),
      })),
    [onSort, sortDescValue, sortFieldValue]
  );

  const currentTabIndex = useMemo(
    () => {
      const index = tabs.findIndex((tab) =>
        matchPath(location.pathname, { path: tab.path, exact: true })
      );
      return index === -1 ? 0 : index;
    },
    [location.pathname, tabs]
  );

  useEffect(() => {
    if (matchPath(location.pathname, { path: ROUTES.PROJECTS })) {
      const key = PROJECT_SORT_OPTION_KEYS[0];
      initialSort(key, PROJECT_DESC_SORT_OPTIONS[key]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, currentTabIndex]);

  const defaultConfig = {
    appBar: {
      title: DefaultStrings.TITLE,
      sort: projectSortOptions,
      tabDefault: tabs[currentTabIndex]?.key,
      onSearch: (value) =>
        value && value.length > 0 ? onSearch(value) : onSearch(null),
      tabs,
    },
    actionButton: {
      actions: [
        {
          icon: <AddToQueueOutlinedIcon />,
          name: ProjectStrings.ADD_PROJECT_DIALOG_CREATE,
          onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_CREATE, true),
          disabled: selectedOrganisationId && !canWriteProjects,
        },
        {
          icon: <QueuePlayNextOutlinedIcon />,
          name: ProjectStrings.ADD_PROJECT_DIALOG_JOIN,
          onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_JOIN, true),
        },
      ],
    },
  };

  return [
    {
      // default route
      // shared projects, my projects, all projects
      path: tabs[currentTabIndex]?.path,
      ...defaultConfig
    },
    {
      // join project
      path: ROUTES.PROJECT_JOIN,
      ...defaultConfig
    },
    {
      // new project
      path: ROUTES.PROJECT_NEW,
      ...defaultConfig
    },
  ];
};
