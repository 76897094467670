export const ORG_TABS_KEYS = {
  DASHBOARD: "DASHBOARD",
  PROJECTS: "PROJECTS",
  TEAM: "TEAM",
  GUESTS: "GUESTS",
  ACTIVITY: "ACTIVITY",
  SETTINGS: "SETTINGS",
};

/** Organisation sort options */
export const ORGANISATION_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "modifiedDate",
  SORT_OPTION_NAME: "name",
};

// default sort options, e.g. descending or ascending
// descending is true, ascending is false
export const ORGANISATION_DESC_SORT_OPTIONS = {
  [Object.keys(ORGANISATION_SORT_OPTION_FIELD)[0]]: true, // most recent
  [Object.keys(ORGANISATION_SORT_OPTION_FIELD)[1]]: false, // name
};

export const ORGANISATION_SORT_OPTION_KEYS = Object.keys(
  ORGANISATION_SORT_OPTION_FIELD
);

/** Project sort options */
export const PROJECT_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "modified_date",
  SORT_OPTION_NAME: "name",
  SORT_OPTION_BRAND: "brand",
};

// default sort options, e.g. descending or ascending
// descending is true, ascending is false
export const PROJECT_DESC_SORT_OPTIONS = {
  [Object.keys(PROJECT_SORT_OPTION_FIELD)[0]]: true, // most recent
  [Object.keys(PROJECT_SORT_OPTION_FIELD)[1]]: false, // name
  [Object.keys(PROJECT_SORT_OPTION_FIELD)[2]]: false, // brand
};

export const PROJECT_SORT_OPTION_KEYS = Object.keys(PROJECT_SORT_OPTION_FIELD);

/** Team sort options */
export const TEAM_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "updatedAt",
  SORT_OPTION_NAME: "name",
  SORT_OPTION_ROLE: "permissionsKey",
};

// default sort options, e.g. descending or ascending
// descending is true, ascending is false
export const TEAM_DESC_SORT_OPTIONS = {
  [Object.keys(TEAM_SORT_OPTION_FIELD)[0]]: true, // most recent
  [Object.keys(TEAM_SORT_OPTION_FIELD)[1]]: false, // name
  [Object.keys(TEAM_SORT_OPTION_FIELD)[2]]: false, // role
};

export const TEAM_SORT_OPTION_KEYS = Object.keys(TEAM_SORT_OPTION_FIELD);

export const GUEST_SORT_OPTION_KEYS = Object.keys(TEAM_SORT_OPTION_FIELD).slice(
  0,
  2
);

/** Devices sort options */
export const DEVICE_SORT_OPTION_FIELD = {
  SORT_OPTION_MOSTRECENT: "updatedAt",
  SORT_OPTION_IMEI: "deviceId",
  SORT_OPTION_STOREID: "storeId",
  SORT_OPTION_ONLINE: "updatedAt",
  SORT_OPTION_COMPLIANCE: "state",
};

// default sort options, e.g. descending or ascending
// descending is true, ascending is false
export const DEVICE_DESC_SORT_OPTIONS = {
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[0]]: true, // most recent
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[1]]: false, // imei
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[2]]: false, // storeId
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[3]]: true, // online
  [Object.keys(DEVICE_SORT_OPTION_FIELD)[4]]: true, // compliance
};

export const DEVICE_SORT_OPTION_KEYS = Object.keys(DEVICE_SORT_OPTION_FIELD);

export const navigationBarHeight = "80px";
export const navigationBarWidth = "80px";
