const STRINGS = {
  TAB_TITLE_OVERVIEW: "Overview",
  TAB_TITLE_MEDIA: "Media",
  TAB_TITLE_DEVICES: "Devices",
  TAB_TITLE_ANALYTICS: "Analytics",
  TAB_TITLE_TEAM: "Team",
  TAB_TITLE_SHARED_PROJECTS: "Shared With Me",
  TAB_TITLE_MY_PROJECTS: "My Projects",
  TAB_TITLE_ALL_PROJECTS: "All Projects",

  PROJECT_NOT_ASSIGNED: "Not assigned",
  PROJECT_DIALOG_UPDATE_SUCCESS: "Project successfully updated",

  PROJECTS_FILTER_ORGANISATION_NAME: "Organisation",
  PROJECTS_FILTER_ALL_ORGANISATION: "All",
  PROJECTS_FILTER_IN_ORGANISATION: "In Organisation",
  PROJECTS_FILTER_NOT_IN_ORGANISATION: "Not In Organisation",
  PROJECTS_NO_IN_ORGANISATION: "No projects in your organisation",
  PROJECTS_NO_IN_ORGANISATION_DESC:
    "There are no projects available to you in your organisation",
  PROJECTS_NO_FOR_SEARCH: "No projects found",
  PROJECTS_NO_PROJECTS: "No projects",
  PROJECTS_NO_MY_PROJECTS_DESC: "You have not created or joined any projects",
  PROJECTS_ADD_FIRST: "Add A First project",

  ACTIVITY_APPROVED_MEDIA_MSG: "I've just approved the project media.",
  ACTIVITY_UPDATED_MEDIA_MSG: "I've just updated the project media.",
  ACTIVITY_UPDATED_APPS_MSG: "I've just updated the project apps.",

  ADD_PROJECT_DIALOG_TITLE: "Add a project",
  ADD_PROJECT_DIALOG_CREATE: "Create a new project",
  ADD_PROJECT_DIALOG_CREATE_BUTTON: "Create",
  ADD_PROJECT_DIALOG_CREATE_SUCCESS: "Project successfully created",
  ADD_PROJECT_DIALOG_JOIN: "Join an existing project",
  ADD_PROJECT_DIALOG_ENTER_CODE: "Project code",
  ADD_PROJECT_JOIN_BUTTON: "Join",
  ADD_PROJECT_JOIN_ERROR: "Invalid project code!",
  ADD_PROJECT_JOIN_SUCCEED: "Project joined",
  ADD_PROJECT_JOIN_JOINED_ALREADY: "Project already joined",
  ADD_PROJECT_JOIN_PRIVATE: "This project is private",
  ADD_PROJECT_JOIN_GUEST_DENIED: "This project is not open to guests",
  ADD_PROJECT_JOIN_APPROVAL_SUBMITTED:
    "Your request to join the project has been submitted",
  ADD_PROJECT_JOIN_APPROVAL_WAITING:
    "Your request to join the project waiting for approval",
  ADD_PROJECT_JOIN_APPROVED: "Project join successfully approved",
  ADD_PROJECT_JOIN_REJECT: "Project join successfully rejected",
  ADD_PROJECT_JOIN_APPROVE_ALL_CONFIRM_TITLE: "Approve Users",
  ADD_PROJECT_JOIN_APPROVE_ALL_CONFIRM_DESC:
    "Are you sure you want to approve all users waiting to join the project?",

  ADD_PROJECT_JOIN_REJECT_ALL_CONFIRM_TITLE: "Reject Users",
  ADD_PROJECT_JOIN_REJECT_ALL_CONFIRM_DESC:
    "Are you sure you want to reject all users waiting to join the project?",

  ADD_PROJECT_JOIN_APPROVE_CONFIRM_TITLE: 'Approve "{name}"',
  ADD_PROJECT_JOIN_APPROVE_CONFIRM_DESC:
    'Are you sure you want to approve "{name}" to join the project?',
  ADD_PROJECT_JOIN_REJECT_CONFIRM_TITLE: 'Reject "{name}"',
  ADD_PROJECT_JOIN_REJECT_CONFIRM_DESC:
    'Are you sure you want to reject "{name}" to join the project?',

  ANALYTICS_TITLE: "Analytics Code",
  ANALYTICS_NA_TITLE: "Analytics is not available on mobile.",
  ANALYTICS_NA_DESC:
    "Please visit our website from a laptop or PC to take full advantage of our services.",
  ANALYTICS_PLACEHOLDER_TITLE: "No analytics in your project",
  ANALYTICS_PLACEHOLDER_DESC:
    "Analytics allow you to monitor your project and user interactions with your media",
  ANALYTICS_TRACKING_DETAILS_TITLE: "Tracking Details",
  ANALYTICS_TRACKING_CODE: "Tracking Code",
  ANALYTICS_API_SECRET: "API Secret",
  ANALYTICS_MENU_EDIT_ANALYTICS_CODE: "Edit Analytics Code",
  ANALYTICS_MENU_EDIT_TRACKING_DETAILS: "Edit Tracking Details (admin)",

  ERROR_NO_ACCESS: "No access to this project",

  MEDIA_APPROVE: "$name approved media.",
  MEDIA_UPDATE: "$name updated media.",
  MEDIA_APPROVE_CONFIRM: 'Are you sure you want to approve "{draft}"?',
  MEDIA_APPROVE_OK: "Project media approved!",
  MEDIA_EDIT_TITLE: "Edit Media",

  MEDIA_LIVE_CONTENT: "Live Content",
  MEDIA_LIVE: "Live",

  MEDIA_DRAFT_TAG_MYSTORIES: "MyStories",
  MEDIA_DRAFT_TAG_PREVIEWING: "Previewing",

  MEDIA_DRAFT_TITLE: "Media Content Drafts ({count})",
  MEDIA_DRAFT_NEW_DRAFT: "New Draft",
  MEDIA_DRAFT_NAME: "Draft Name",
  MEDIA_DRAFT_PUBLISHING: "Publishing draft",
  MEDIA_DRAFT_PUBLISHING_DESC: "Do not close or refresh this page",

  MEDIA_DRAFT_APPROVE_MSG: 'I\'ve approved media draft "{draft}"',
  MEDIA_DRAFT_CREATE_MSG: 'I\'ve created media draft "{draft}"',
  MEDIA_DRAFT_UPDATE_MSG: 'I\'ve updated media draft "{draft}"',
  MEDIA_DRAFT_DELETE_MSG: 'I\'ve deleted media draft "{draft}"',
  MEDIA_DRAFT_PUBLISH_MSG: 'I\'ve published media draft "{draft}"',

  MEDIA_DRAFT_CREATE_OK: "Draft created",
  MEDIA_DRAFT_UPDATE_OK: "Draft updated",
  MEDIA_DRAFT_DELETE_OK: "Draft deleted",
  MEDIA_DRAFT_PUBLISH_OK: "Draft published",
  MEDIA_DRAFT_UPDATE_NAME_OK: "Draft name updated",

  MEDIA_DRAFT_CONFIRM_DELETE:
    'Are you sure you want to delete draft "{name}"? This process cannot be undone.',
  MEDIA_DRAFT_CONFIRM_PUBLISH:
    'Are you sure you want to publish draft "{name}"? All associated showroom links will be deleted as well.',

  MEDIA_DRAFT_MENU_EDIT: "Edit",
  MEDIA_DRAFT_MENU_CHANGE_NAME: "Change Name",
  MEDIA_DRAFT_MENU_MANAGE: "Manage",
  MEDIA_DRAFT_MENU_DELETE: "Delete Draft",
  MEDIA_DRAFT_MENU_PUBLISH: "Publish Draft",

  SHOWROOM_TITLE: "Showroom",
  SHOWROOM_GENERATOR_TITLE: "Link Generator",
  SHOWROOM_GENERATOR_BUTTON: "Generate",
  SHOWROOM_GENERATOR_DESC: "To showcase your media outside of MyProjects.",
  SHOWROOM_NAME_LABEL: "Name",
  SHOWROOM_TYPE_LABEL: "Type",
  SHOWROOM_MENU_ITEM_PREVIEW: "Media Preview",
  SHOWROOM_MENU_ITEM_EMBED: "Embed",
  SHOWROOM_MENU_ITEM_QR_CODE: "QR Code",
  SHOWROOM_DATE_LABEL: "Expiry Date",
  SHOWROOM_HEIGHT_LABEL: "Height",
  SHOWROOM_WIDTH_LABEL: "Width",
  SHOWROOM_RETAILER_LABEL: "Retailer",
  SHOWROOM_POSTCODE_LABEL: "Postcode",
  SHOWROOM_STORE_ID_LABEL: "StoreId",
  SHOWROOM_CUSTOM_LABEL: "Custom",
  SHOWROOM_PASSWORD_LABEL: "Password",
  SHOWROOM_UPDATE_PASSWORD_LABEL: "Change new password",
  SHOWROOM_LINK_GENERATED: "Link generated",
  SHOWROOM_LINK_UPDATED: "Link updated",
  SHOWROOM_EMBED_BUTTON: "Copy Code",
  SHOWROOM_PREVIEW_BUTTON: "Copy Link",
  SHOWROOM_QR_BUTTON: "Download QR",
  SHOWROOM_CUSTOMISE_QR_BUTTON: "Customise QR (Beta)",
  SHOWROOM_LINK_DELETE: "Delete",
  SHOWROOM_LINK_DELETED: "Link deleted",
  SHOWROOM_LINK_EDIT: "Edit",
  SHOWROOM_EDIT_DIALOG_TITLE: "Edit Link",
  SHOWROOM_LINK_COPIED: "Link copied",
  SHOWROOM_CUSTOMISE_TITLE: "Customise",
  SHOWROOM_TAGS_TITLE: "Tags",
  SHOWROOM_PASSWORD_TITLE: "Set Password",
  SHOWROOM_UPDATE_PASSWORD_TITLE: "Change password",
  // TODO: strings file should only contain visible and translatable text
  // string constants used by business logic should be defined in corresponding file (eg. Service file)
  SHOWROOM_PREVIEW: "preview",
  SHOWROOM_EMBED: "embed",
  SHOWROOM_QR: "qr",
  DEVICE_QR: "qrId",
  SHOWROOM_EXPIRES_LABEL: "Expires on",
  SHOWROOM_CREATED_LABEL: "Created on",
  SHOWROOM_EXPIRED: "Expired",
  SHOWROOM_ADD_NEW_LINK_TITLE: "Your showroom links will appear here.",
  SHOWROOM_ADD_NEW_LINK_MSG:
    "To create your first one, fill in the details on the right and select Generate.",
  SHOWROOM_LINK_DELETE_CONFIRM: `Are you sure you want to delete "{name}"? This process cannot be undone.`,
  SHOWROOM_DRAFT_LABEL: "Draft",
  SHOWROOM_NO_DRAFT:
    "Create a media draft first to be able to generate a showroom link.",
  SHOWROOM_TITLE_ANALYTICS: "Analytics",
  SHOWROOM_TRACKING_ID_GA4: "Tracking ID",
  SHOWROOM_PASSWORD_REQUIRED: "This content is private",
  SHOWROOM_PASSWORD_ERROR_MESSAGE: "Incorrect password",
  SHOWROOM_CUSTOM_QR_TITLE: "Customise your QR",

  MEDIA_EDIT_APPS_TITLE: "Edit Apps",
  MEDIA_DEFAULT_APPS_TITLE: "Default Apps",
  MEDIA_DEFAULT_APPS_DESC:
    "Essential apps required to keep MyPlayer platform running smoothly.",
  MEDIA_MYPLAYER_APPS_TITLE: "MyPlayer Apps",
  MEDIA_MYPLAYER_APPS_DESC:
    "Apps that comes together to create unique experience.",

  MEDIA_PROJECT_CONTENT_TITLE: "Live Content",
  MEDIA_PROJECT_CONTENT_BUTTON: "Upload Draft",
  MEDIA_PROJECT_CONTENT_FILE_SIZE: "File Size:",
  MEDIA_PROJECT_CONTENT_CONTAINS: "Contains:",
  MEDIA_PROJECT_CONTENT_LAST_UPDATED: "Last Updated:",
  MEDIA_PROJECT_CONTENT_NEVER: "Never",
  MEDIA_PROJECT_CONTENT_CONTAINS_CONTENT: " files",
  MEDIA_PROJECT_CONTENT_NO_CONTENT_TITLE: "No media in your project",
  MEDIA_PROJECT_CONTENT_NO_CONTENT_DESC:
    "Media is the content devices will play when attached to the project",
  MEDIA_PROJECT_CONTENT_NA_TITLE: "Media is not available on mobile.",
  MEDIA_PROJECT_CONTENT_NA_DESC:
    "Please visit our website from a laptop or PC to take full advantage of our services.",

  MEDIA_UPLOAD_CONFIRM: "Upload new content?",
  MEDIA_UPLOAD_CLEAN: "Clean upload. This will delete any existing files.",
  MEDIA_UPLOADING_TITLE: "Uploading",
  MEDIA_DELETING: "Deleting",

  MEDIA_RESOLUTION_TITLE: "Resolution",
  MEDIA_RESOLUTION_BUTTON: "Edit Resolution",
  RESOLUTION_TYPE_PORTRAIT: "Portrait",
  RESOLUTION_TYPE_LANDSCAPE: "Landscape",
  RESOLUTION_TYPE_SQUARE: "Square",

  PROJECT_DIALOG_NAME: "Project Name",
  PROJECT_DIALOG_BRAND: "Brand",
  PROJECT_DIALOG_DESCRIPTION: "Description",

  SORT_OPTION_MOSTRECENT: "Most Recent",
  SORT_OPTION_NAME: "Name",
  SORT_OPTION_BRAND: "Brand",
  SORT_OPTION_DATE: "Date",
  SORT_OPTION_ROLE: "Role",

  OVERVIEW_DEVICES_TITLE: "Devices",
  OVERVIEW_SMART_TAGS_STAT_TITLE: "Smart Tags",
  OVERVIEW_PIN_CARD_TITLE: "Pin",
  OVERVIEW_PIN_CARD_INFO:
    "Pin number enables you to unlock and gain full access to your devices. Pin number differs between projects and cannot be changed.",
  OVERVIEW_PIN_CARD_ADMIN: "Admin",
  OVERVIEW_PIN_CARD_SECURITY: "Security",
  OVERVIEW_STATUS_CARD_TITLE: "Status",
  OVERVIEW_STATUS_CARD_INFO:
    "Status widget is a quick way to identify which stage the project is in. This information then can be used to sort your projects in the Projects page.",
  OVERVIEW_HARDWARE_CARD_TITLE: "Hardware",
  OVERVIEW_HARDWARE_CARD_INFO:
    "Hardware widget allows to note down the necessary hardware and accessory required for the project.",
  OVERVIEW_HARDWARE_INPUT_TITLE: "Add hardware...",
  OVERVIEW_HARDWARE_MAX_LIMIT: "Maximum hardware limit is {number}!",
  OVERVIEW_MENU_DELETE: "Delete Project",
  OVERVIEW_MENU_LEAVE: "Leave Project",
  OVERVIEW_MENU_JOIN: "Join Project",
  OVERVIEW_MENU_TRANSFER: "Transfer Project",
  OVERVIEW_PROJECT_DELETE_CONFIRM:
    "Are you sure you want to delete this project?",
  OVERVIEW_PROJECT_LEAVE_CONFIRM:
    "Are you sure you want to stop participating in this project?",
  OVERVIEW_PROJECT_TRANSFER_CONFIRM_TITLE: "Transfer Project",
  OVERVIEW_PROJECT_TRANSFER_CONFIRM_DESC:
    "Are you sure you want to transfer this project to {organisation}?",
  OVERVIEW_PROJECT_TRANSFER_CONFIRM_KEEP_USERS_DESC:
    "Keep all users in the project and add them as guests to organisation",
  OVERVIEW_PROJECT_TRANSFER_CONFIRM_NOT_KEEP_USERS_DESC:
    "Remove all users from project",
  OVERVIEW_PROJECT_TRANSFER_CONFIRM_KEEP_USERS_TITLE: "Keep all users",
  OVERVIEW_PROJECT_TRANSFER_CONFIRM_REMOVE_USERS_TITLE: "Remove all users",
  OVERVIEW_PROJECT_TRANSFER_SUCCESS:
    "Project successfully transfered to {organisation}",
  OVERVIEW_PROJECT_TRANSFER_ERROR: "Something went wrong with project transfer",
  OVERVIEW_PROJECT_DELETED: "Project deleted!",
  OVERVIEW_PROJECT_LEFT: "Left project",
  OVERVIEW_PROJECT_TRANSFER_TO_ORGANISATION_TITLE:
    "Select An Organisation To Transfer To",

  LIST_MORE_HIDDEN_MEDIA_LINKS: "{} more hidden links",
  LIST_NO_PROJECTS: "No projects",
  LIST_NO_FOR_SEARCH: "No projects found",

  ACTION_EDIT: "Edit",
  ACTION_APPROVE: "Approve",

  // tasks
  TASKS_ADD_TASK_BUTTON: "Add Task",
  TASKS_ADD_TASK_DESC: "Set tasks for the devices during deployment",
  TASKS_ADD_TASK_TITLE: "Task",
  TASKS_TARGET_LABEL: "Target",
  TASKS_TARGET_CUSTOM_LABEL: "Custom",
  TASKS_TASK_LABEL: "Task",
  TASKS_TASK_NAME_LABEL: "Task Name",
  TASKS_COMMAND_LABEL: "Command",
  TASKS_MENU_COLLAPSE_TASK: "Collapse Task",
  TASKS_MENU_EXPAND_TASK: "Expand Task",
  TASKS_MENU_DELETE_TASK: "Delete Task",
  TASKS_TASK_TAG_ASYNC: "async",
  TASKS_TASK_TAG_REQUIRESUCCESS: "requireSuccess",
  TASKS_TASK_TAG_SUDO: "sudo",
  TASKS_MSG_TASK_ADDED: "Task added",
  TASKS_MSG_TASK_REMOVED: "Task removed",
  TASKS_MSG_TASK_MOVED: "Task moved",
  TASKS_MSG_CONFIRM_REMOVE_TASK: "Are you sure you want to remove task",

  // chart
  CHART_HEARTBEATS_TITLE: "Project Heartbeat",
  CHART_HEARTBEATS_X_LABEL: "Last 90 Days",
  CHART_HEARTBEATS_Y_LABEL: "Online Devices",
  CHART_DEVICE_ONLINE: " Device Online",
  CHART_DEVICES_ONLINE: " Devices Online",

  USERS_NO_IN_PROJECT_TITLE: "No team members in your project",
  USERS_NO_IN_PROJECT_DESC: "Team members have access to your project",
  USERS_NO_FOR_SEARCH: "No team members found",
  USERS_NO_IN_PROJECT_ACTION_TITLE: "Add Team Members",

  CHATROOM_NO_MESSAGE_TITLE: "No activity in your project",
  CHATROOM_NO_MESSAGE_DESC: "There has been no activity in your project",
};

export default STRINGS;
