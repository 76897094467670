import React, { useContext, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import TabletIcon from "@material-ui/icons/Tablet";
import { useRouteMatch } from "react-router-dom";

import {
  useUserProjectPermissions,
  useProjectInfo,
  useUserInProject,
  useProjectDraftExist,
  useProjectDraftApprovals,
} from "services/ProjectService";
import {
  useTotalDevicesCount,
  useLiveAlldevicesCount,
} from "services/DeviceService";
import { useMyState } from "services/StateService";
import { getProjectCreatorId } from "utils/projectUtils";
import { ProjectStrings, DeviceStrings } from "strings";
import MainContext from "context/MainContext";
import { TEAM_SORT_OPTION_KEYS, TEAM_DESC_SORT_OPTIONS } from "ui/pageLayout/config";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";
import { QrCodeIcon } from "assets/icons";
import { DEVICE_SORT_OPTION_KEYS, DEVICE_DESC_SORT_OPTIONS } from "./config";
import { ROUTES } from "route";

export const useProjectActions = ({ userId, projectId }) => {
  const location = useLocation();
  const { sortDescValue, sortFieldValue, actionType, onSort, onAction, initialSort } =
    useContext(MainContext);

  const {
    isSuperAdmin,
    canDeleteProject,
    canReadShowroom,
    canReadDeviceExtra,
    canReadDevices,
    canWriteProjectApprovals,
  } = useUserProjectPermissions({
    userId,
    projectId,
  });
  const isJoinedProj = useUserInProject({ projectId, userId });
  const info = useProjectInfo({ projectId, userId });
  const canLeave = info && getProjectCreatorId(info) !== userId; // anyone but the creator
  const allDevices = useTotalDevicesCount(canReadDevices && projectId);
  const allLiveDevices = useLiveAlldevicesCount(canReadDevices && projectId);
  const noDecommissionDevice = allDevices === allLiveDevices;

  const matchPreview = useRouteMatch(ROUTES.PROJECT_MEDIA_PREVIEW);
  const version = useMyState({ path: `${projectId}/media/version` });
  // current state override path param
  const draftId = version?.id ?? matchPreview?.params.draftId;

  const mediaExist = useProjectDraftExist({
    projectId,
    draftName: draftId,
  });
  const approvals = useProjectDraftApprovals({
    projectId,
    draftName: draftId,
  });

  const hasApproved = (userId, approvals) => {
    if (!userId || !approvals) return false;
    return Object.keys(approvals).some((id) => id === userId);
  };

  // can approve if there is media and not already approved and can write to project
  const canApprove =
    mediaExist && !hasApproved(userId, approvals) && canWriteProjectApprovals;

  const deleteProject = {
    label: ProjectStrings.OVERVIEW_MENU_DELETE,
    onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_DELETE, true),
    disabled: !canDeleteProject,
    color: "error",
  };

  const joinProject = {
    label: ProjectStrings.OVERVIEW_MENU_JOIN,
    onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_JOIN, projectId),
    disabled: isJoinedProj === true,
  };

  const leaveProject = {
    label: ProjectStrings.OVERVIEW_MENU_LEAVE,
    onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_LEAVE, true),
    disabled: !canLeave || isJoinedProj === false,
  };

  const transferProject = {
    label: ProjectStrings.OVERVIEW_MENU_TRANSFER,
    onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_TRANSFER, true),
  };

  const mediaEdit = {
    icon: <EditIcon />,
    name: ProjectStrings.ACTION_EDIT,
    onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_EDIT, true),
  };

  const mediaApprove = {
    icon: <ThumbUpAltOutlinedIcon />,
    name: ProjectStrings.ACTION_APPROVE,
    onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_APPROVE, true),
    disabled: !canApprove,
  };

  const mediaShare = {
    icon: <ShareOutlinedIcon />,
    name: ProjectStrings.SHOWROOM_TITLE,
    onClick: () => onAction(GLOBAL_ACION_TYPES.PROJECT_MEDIA_SHARE, true),
    disabled: !canReadShowroom,
  };

  const createQrDevice = {
    icon: <QrCodeIcon />,
    name: DeviceStrings.MENU_CREATE_QR_DEVICE,
    onClick: () =>
      onAction(GLOBAL_ACION_TYPES.PROJECT_DEVICES_QR_DEVICE_CREATE, true),
    disabled: !canReadDeviceExtra,
  };

  const createDisplayDevice = {
    icon: <TabletIcon />,
    name: DeviceStrings.MENU_CREATE_DISPLAY_DEVICE,
    onClick: () =>
      onAction(GLOBAL_ACION_TYPES.PROJECT_DEVICES_INSTRACTION, true),
    disabled: !canReadDeviceExtra,
  };
  // team sort options
  const teamSortOptions = TEAM_SORT_OPTION_KEYS.map((opt) => ({
    label: ProjectStrings[opt],
    desc: opt in sortDescValue ? sortDescValue[opt] : TEAM_DESC_SORT_OPTIONS[opt],
    selected: opt === (sortFieldValue || TEAM_SORT_OPTION_KEYS[0]),
    onClick: () => onSort(opt, TEAM_DESC_SORT_OPTIONS[opt]),
  }));

  // devices sort options
  const devicesSortOptions = DEVICE_SORT_OPTION_KEYS.map((opt) => ({
    label: DeviceStrings[opt],
    desc: opt in sortDescValue ? sortDescValue[opt] : DEVICE_DESC_SORT_OPTIONS[opt],
    selected: opt === (sortFieldValue || DEVICE_SORT_OPTION_KEYS[0]),
    onClick: () => onSort(opt, DEVICE_DESC_SORT_OPTIONS[opt]),
  }));

  const devicesOverflow = [
    {
      label: DeviceStrings.MENU_SELECT_MULTI_DEVICES,
      onClick: () =>
        onAction(
          GLOBAL_ACION_TYPES.PROJECT_DEVICES_MULTIPLE,
          !actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_MULTIPLE]
        ),
    },
    {
      label: actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE]
        ? DeviceStrings.MENU_DECOMMISSION_DEVICES
        : DeviceStrings.MENU_LIVE_DEVICES,
      onClick: () =>
        onAction(
          GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE,
          !actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE]
        ),
      disabled: !canReadDevices,
    },
    {
      label: actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_EXPORTING]
        ? DeviceStrings.MENU_EXPORT_CSV_DOWNLOADING
        : DeviceStrings.MENU_EXPORT_CSV,
      onClick: () =>
        onAction(GLOBAL_ACION_TYPES.PROJECT_DEVICES_EXPORTING, true),
      disabled:
        !canReadDevices || // no permission
        actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_EXPORTING] || // already exporting
        (actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE] && !allLiveDevices) || // no live device to export
        (!actionType[GLOBAL_ACION_TYPES.PROJECT_DEVICES_LIVE] &&
          !noDecommissionDevice), // no decommission device to export
    },
  ];

  if (isSuperAdmin) {
    devicesOverflow.push({
      label: DeviceStrings.MENU_EXPORT_CSV_ADMIN,
      onClick: () =>
        onAction(GLOBAL_ACION_TYPES.PROJECT_DEVICES_EXPORTING_ADMIN, true),
    });
  }

  useEffect(() => {
    if (matchPath(location.pathname, { path: ROUTES.PROJECT_DEVICES })) {
      const key = DEVICE_SORT_OPTION_KEYS[0];
      initialSort(key, DEVICE_DESC_SORT_OPTIONS[key]);
    }
    if (matchPath(location.pathname, { path: ROUTES.PROJECT_TEAM })) {
      const key = TEAM_SORT_OPTION_KEYS[0];
      initialSort(key, TEAM_DESC_SORT_OPTIONS[key]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return {
    deleteProject,
    joinProject,
    leaveProject,
    transferProject,
    mediaEdit,
    mediaApprove,
    mediaShare,
    teamSortOptions,
    createQrDevice,
    createDisplayDevice,
    devicesSortOptions,
    devicesOverflow,
  };
};
