import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import Button from "@material-ui/core/Button";

import RestrictedContent from "ui/RestrictedContent";
import { TeamMemberDialog } from "ui/dialogs";
import {
  deleteOrganisationUser,
  updateOrganisationUserRole,
  getOrganisationUsers,
  useUserOrganisationPermissions,
} from "services/OrganisationService";
import {
  useProjectUsersLastUpdate,
  useOrganisationUsersLastUpdate,
} from "services/UserService";
import { useTeamOrganisationPermissionsOptions } from "services/PermissionService";
import { useMobileLayout } from "hooks/uiHooks";
import { ROUTES } from "route";
import { TEAM_SORT_OPTION_FIELD, TEAM_SORT_OPTION_KEYS, TEAM_DESC_SORT_OPTIONS } from "ui/pageLayout/config";
import { useStyles } from "./style";
import { DefaultStrings, OrganisationStrings } from "strings";
import { isLoading } from "utils/uiUtils";
import InfiniteScroll from "ui/InfiniteScroll";
import OrganisationTeamMemberCard from "ui/cards/teamMemberCard/OrganisationTeamMemberCard";
import Spinner from "ui/Spinner";
import { Select } from "components";
import { USER_ROLE_VALUES } from "./config";
import { EmptyData } from "ui/emptyData";
import PendingInvitesCard from "ui/cards/pendingInvitesCard/PendingInvitesCard";
import { PeopleIcon, SearchIcon, GuestIcon } from "assets/icons";
import MainContext from "context/MainContext";
import { InviteUserDialog } from "ui/dialogs";
import { GLOBAL_ACION_TYPES } from "context/globalActionTypes";

const LIMIT_DEFAULT = 20;

const Team = (props) => {
  const { userId } = props;
  const { organisationId } = useParams();
  const isTeamPage = useRouteMatch(ROUTES.ORGANISATION_TEAM);
  const mobile = useMobileLayout();
  const classes = useStyles(mobile);
  const { sortFieldValue, sortDescValue, searchValue, actionType, onAction } =
    useContext(MainContext);

  const [selectedUser, setSelectedUser] = useState(null);
  const [progressId, setProgressId] = useState();
  const [startAfter, setStartAfter] = useState();
  const [usersIds, setUsersIds] = useState();
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [selectedRole, setSelectedRole] = useState(USER_ROLE_VALUES.ALL);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [fetchedAt, setFetchedAt] = useState();

  const onInviteCloseHandle = () =>
    onAction(GLOBAL_ACION_TYPES.ORGANISATION_INVITE, false);
  const onInviteSendHandle = () =>
    onAction(GLOBAL_ACION_TYPES.ORGANISATION_INVITE, false);

  const organisationUsersLastUpdate = useOrganisationUsersLastUpdate();
  const projectUsersLastUpdate = useProjectUsersLastUpdate();

  const userPermissions = useUserOrganisationPermissions({
    userId,
    organisationId,
  });
  const selectedUserPermissions = useUserOrganisationPermissions({
    userId: selectedUser?.userId,
    organisationId,
  });
  const { canReadUsers, canReadUserPermissions, canWriteOrganisation } =
    userPermissions;
  const teamPermissionsOptions = useTeamOrganisationPermissionsOptions();

  const userRolesOptions = useMemo(() => {
    const allUsers = {
      title: "All",
      value: USER_ROLE_VALUES.ALL,
    };
    if (teamPermissionsOptions) {
      const perms = [...teamPermissionsOptions];
      perms.unshift(allUsers);
      return perms;
    }
    return teamPermissionsOptions;
  }, [teamPermissionsOptions]);

  const resetData = () => {
    setIsFetchedAll(false);
    setIsRequestSent(true);
    setStartAfter();
  };

  const permissionNames = useMemo(() => 
    isTeamPage ? teamPermissionsOptions : [{title: OrganisationStrings.PERMISSIONS_GUEST, value: 'guest_default'}], 
    [isTeamPage, teamPermissionsOptions]
  );

  const emptyDataText = useMemo(() => {
    if (usersIds && usersIds.length) return {};

    const dataText = {
      actionTitle: null,
      icon: usersIds?.length === 0 ? <GuestIcon /> : <SearchIcon />,
    };

    if (isTeamPage) {
      if (usersIds?.length === 0) {
        dataText.title = OrganisationStrings.USERS_NO_IN_ORGANISATION;
        dataText.description =
          OrganisationStrings.USERS_NO_IN_ORGANISATION_DESC;
        dataText.actionTitle = searchValue ? null : null; // TODO: here will be text to add a new team member
        dataText.icon = <PeopleIcon />;
      }
      if (searchValue || selectedRole !== USER_ROLE_VALUES.ALL) {
        dataText.title = OrganisationStrings.USERS_NO_FOR_SEARCH;
        dataText.icon = <SearchIcon />;
      }
    } else {
      if (usersIds?.length === 0) {
        dataText.title = OrganisationStrings.USERS_NO_GUESTS_IN_ORGANISATION;
        dataText.description =
          OrganisationStrings.USERS_NO_GUESTS_IN_ORGANISATION_DESC;
        dataText.actionTitle = searchValue ? null : null; // TODO: here will be text to add a new team member
        dataText.icon = <GuestIcon />;
      }
      if (searchValue) {
        dataText.title = OrganisationStrings.USERS_NO_GUESTS_FOR_SEARCH;
        dataText.icon = <SearchIcon />;
      }
    }

    return dataText;
  }, [
    isTeamPage,
    searchValue,
    selectedRole,
    usersIds,
  ]);

  const getParams = useCallback(
    (params) => {
      const { sortDesc, sortValue, lastKey, userRole } = params;
      const orderBy =
        sortValue && sortValue in TEAM_SORT_OPTION_FIELD
          ? TEAM_SORT_OPTION_FIELD[sortValue]
          : TEAM_SORT_OPTION_FIELD[TEAM_SORT_OPTION_KEYS[0]];

      return {
        limit: LIMIT_DEFAULT,
        orderBy,
        searchValue: params.searchValue,
        lastKey,
        orderDesc: sortValue in sortDesc
          ? sortDesc[sortValue]
          : TEAM_DESC_SORT_OPTIONS[sortValue],
        userRole: isTeamPage ? userRole : null,
      };
    },
    [isTeamPage]
  );

  const fetchData = useCallback(
    async (params, isLoadingMore=false) => {
      const requestParams = getParams({
        userRole: selectedRole,
        sortDesc: sortDescValue,
        sortValue: sortFieldValue,
        searchValue,
        ...params,
      });

      if (!isLoadingMore) {
        setLoading(true);
      } 

      try {
        const response = await getOrganisationUsers({
          organisationId,
          ...requestParams,
        });
        const { items, fetchedAt: newFetchedAt } = response.result;
        setFetchedAt(newFetchedAt);

        if (items.length === 0) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setUsersIds([...usersIds, ...items]);
        } else {
          setUsersIds(items);
          setIsRequestSent(false);
        }

        if (items.length) {
          setStartAfter(items[items.length - 1]);
        }
        if (!isLoadingMore) {
          setLoading(false);
        }

      } catch (err) {
        console.error(err);
        setLoading(false);
        setLoadingMore(false);
      }
    },
    [
      getParams,
      isRequestSent,
      organisationId,
      searchValue,
      selectedRole,
      sortDescValue,
      sortFieldValue,
      usersIds,
    ]
  );

  useEffect(() => {
    if (organisationId) {
    // initial request on page loaded
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId, sortFieldValue, sortDescValue, searchValue]);

  useEffect(() => {
    if (isTeamPage && organisationUsersLastUpdate > fetchedAt) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamPage, fetchedAt, organisationUsersLastUpdate]);

  useEffect(() => {
    if (!isTeamPage && projectUsersLastUpdate > fetchedAt) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamPage, fetchedAt, projectUsersLastUpdate]);

  const onProgressIdHandle = (userId) => setProgressId(userId);
  const onCloseHandle = () => setSelectedUser(null);
  const onRemoveUserHandle = async () => {
    await deleteOrganisationUser({
      organisationId,
      userId: selectedUser.userId,
    });
    fetchData();
  };

  const onUpdateUserRoleHandle = async (role) => {
    await updateOrganisationUserRole({
      organisationId,
      role,
      userId: selectedUser.userId,
    });
    fetchData();
  };

  const configScroll = {
    onLoadMore: async () => {
      if (isFetchedAll || !startAfter || loading) return;

      setLoadingMore(true);
      await fetchData({ lastKey: startAfter }, true);
      setLoadingMore(false);
    },
  };

  const onUserClick = (user) => {
    if (!user.permissionsKey) {
      setSelectedUser({
        ...user,
        permissionsKey: "guest_default", // need this value to show guest permissions in dialog
        permissionName: OrganisationStrings.PERMISSIONS_GUEST,
      });
    } else {
      setSelectedUser(user);
    }
  }

  const onSelectHandle = (ev) => {
    const { name, value } = ev.target;

    if (name === "role") {
      resetData();
      setSelectedRole(value);
      fetchData({ userRole: value });
    }
  };
  const onClickClear = () => {
    resetData();
    setSelectedRole(USER_ROLE_VALUES.ALL);
    fetchData({
      userRole: USER_ROLE_VALUES.ALL,
    });
  };

  const renderContent = () => {
    if (isLoading(usersIds)) return <Spinner />;

    if (!usersIds?.length)
      return (
        <EmptyData
          title={emptyDataText.title}
          description={emptyDataText.description}
          actionTitle={emptyDataText.actionTitle}
          icon={emptyDataText.icon}
        />
      );

    return (
      <div className={classes.root}>
        <InfiniteScroll config={configScroll} size={usersIds.length}>
          {usersIds.map((id) => (
            <div key={id} className={classes.cardContainer}>
              <OrganisationTeamMemberCard
                userId={id}
                organisationId={organisationId}
                onClick={onUserClick}
                canReadPermissions={canReadUserPermissions}
                progress={progressId === id}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <>
      {isTeamPage && (
        <>
          <PendingInvitesCard
            organisationId={organisationId}
            canWrite={canWriteOrganisation}
          />
          <div className={classes.filter}>
            <Select
              name="role"
              label={OrganisationStrings.SORT_OPTION_ROLE}
              value={selectedRole}
              options={userRolesOptions}
              onChange={onSelectHandle}
              disabled={loading}
            />
            <Button
              style={{ margin: "8px" }}
              onClick={onClickClear}
              disabled={loading}
            >
              {DefaultStrings.BUTTON_CLEAR}
            </Button>
          </div>
        </>
      )}
      {loading && <Spinner />}
      <RestrictedContent permitted={canReadUsers}>
        {renderContent()}
        {loadingMore && usersIds?.length !== 0 && (
        <div style={{ position: "relative", width: "100%", height: "80px" }}>
          <Spinner size={30} />
        </div>
      )}
      </RestrictedContent>
      <TeamMemberDialog
        targetUser={selectedUser}
        targetUserPermissions={selectedUserPermissions}
        userId={userId}
        userPermissions={userPermissions}
        permissionNames={permissionNames}
        confirmTitle={OrganisationStrings.TEAM_MEMBER_REMOVE_CONFIRM_TITLE}
        confirmDesc={OrganisationStrings.TEAM_MEMBER_REMOVE_CONFIRM_DESC}
        onProgressId={onProgressIdHandle}
        onRemoveUser={onRemoveUserHandle}
        onUpdateUserRole={onUpdateUserRoleHandle}
        onClose={onCloseHandle}
      />
      <InviteUserDialog
        organisationId={organisationId}
        open={actionType[GLOBAL_ACION_TYPES.ORGANISATION_INVITE]}
        onClose={onInviteCloseHandle}
        onSend={onInviteSendHandle}
      />
    </>
  );
};

export default Team;
