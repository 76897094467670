import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useUserInProject, useProjectInfo } from "services/ProjectService";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import { getProjectsRoute } from "route";
import { ProjectStrings } from "strings";

export const withInProjectCheck = (BaseComponent) => {
  // HOC logic using hooks
  return function (props) {
    const { userId } = props;
    const params = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const projectId = params.projectId;

    const info = useProjectInfo({
      userId,
      projectId,
    });

    const { isSuperAdmin, isUserAdmin } = useUserOrganisationPermissions({
      userId,
      organisationId: info?.organisationId,
    });

    const userInProject = useUserInProject({
      projectId,
      userId,
    });

    useEffect(() => {
      if (
        info === null ||
        (isSuperAdmin === false && // user is not super admin
          userInProject === false && // user is not in the project
          isUserAdmin === false) // user is not admin
      ) {
        enqueueSnackbar(ProjectStrings.ERROR_NO_ACCESS, {
          variant: "warning",
        });
        history.push(getProjectsRoute());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, info, userInProject, isSuperAdmin, isUserAdmin]);

    return <BaseComponent {...props} />;
  };
};
